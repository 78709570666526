import {inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {OrganizationState} from '|api/commons';
import {ApiOrganizationService} from '|api/config-server';
import {Option} from '|shared';

const clearCache$ = new Subject<void>();

const CODEBOOK_CACHING_POLICY = {
  maxAge: 60 * 60 * 1000 /* ms = 60min */
};

const TRANSIENT_CODEBOOK_CACHING_POLICY = {
  ...CODEBOOK_CACHING_POLICY,
  cacheBusterObserver: clearCache$,
};

@Injectable({
  providedIn: 'root'
})
export class ConfigCodebookService {

  private apiOrganizationService = inject(ApiOrganizationService);

  // Codebooks with imperatively clearable cache
  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  organizationOptions(): Observable<Option<Nullable<number>>[]> {
    return this.apiOrganizationService.organizationGetAll().pipe(
      map(organizations => organizations.filter(organization => organization.organizationState === OrganizationState.ACTIVE)),
      map(organizations => organizations.map(organization => ({value: organization.id, label: organization.name}))),
    );
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  organizationViewOptions(): Observable<Option<Nullable<number>>[]> {
    return this.organizationOptions().pipe(
      map(organizations => ([{value: null, label: 'Globální pohled'}, ...organizations])),
    );
  }

  resetTransientCodebookCaches() {
    clearCache$.next();
  }

}
