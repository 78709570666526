import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {delay, map, switchMap, tap} from 'rxjs/operators';
import {OrganizationDto, OrganizationState} from '|api/commons';
import {ApiUserService, ConfigUserDto} from '|api/config-server';
import {
  ApplicationRoute,
  castStream,
  createAbsoluteRoute,
  getUserInitials,
  IAuthService,
  LocalStorageKey,
  SKIP_ERROR_DIALOG,
  UserSettingsService
} from '|shared';
import {STATIC_ENVIRONMENT} from '|environments/environment';
import {ConfigAuthority} from '../enums/authority.enum';
import {ConfigRoute} from '../enums/config-routes.enum';

@Injectable({providedIn: 'root'})
export class ConfigAuthService implements IAuthService {

  private router = inject(Router);
  private apiUserService = inject(ApiUserService);
  private userSettingsService = inject(UserSettingsService);

  get bearer(): Nullable<string> {
    return this._bearer;
  }
  set bearer(newBearer: Nullable<string>) {
    if (!STATIC_ENVIRONMENT.production) {
      if (isNil(newBearer)) {
        this.userSettingsService.removeRawValue(LocalStorageKey.NPROD_BEARER);
      } else {
        this.userSettingsService.setRawValue(LocalStorageKey.NPROD_BEARER, newBearer);
      }
    }

    this._bearer = newBearer;
  }

  get isSuperAdmin() {
    return this.currentUser?.authorities.includes(ConfigAuthority.SUPER_ADMIN);
  }

  get isNormalAdmin() {
    return this.currentUser?.authorities.includes(ConfigAuthority.ADMIN);
  }

  get userFullName() {
    return `${this.currentUser?.firstName} ${this.currentUser?.surname}`;
  }

  get userInitials() {
    return getUserInitials(this.userFullName?.split(' ') ?? []);
  }

  get isAuthenticatedWithFunctionalPosition(): boolean {
    return this.bearer !== null;
  }

  get isAuthenticatedWithoutFunctionalPosition(): boolean {
    return this.bearer !== null;
  }

  currentUser: Nullable<ConfigUserDto> = null;

  private _availableOrganizations$ = new BehaviorSubject<OrganizationDto[]>([]);
  availableOrganizations$: Observable<OrganizationDto[]> = this._availableOrganizations$.asObservable();

  private _bearer: Nullable<string> = null;

  constructor() {
    if (!STATIC_ENVIRONMENT.production) {
      this.bearer = this.userSettingsService.getRawValue(LocalStorageKey.NPROD_BEARER);
      this.currentUser = this.userSettingsService.getParsedValue(LocalStorageKey.NPROD_USER) as ConfigUserDto;
      this.reloadOrganizations();
    }
  }

  logIn(_: string, username: string, password: string) {
    this.bearer = window.btoa(`${username}:${password}`);

    return this.apiUserService.userGetUserInfo(
      {
        userOrganizationSecurityWrapper: {},
      },
      SKIP_ERROR_DIALOG
    ).pipe(
      tap({
        next: user => {
          if (!STATIC_ENVIRONMENT.production) {
            this.userSettingsService.setParsedValue(LocalStorageKey.NPROD_USER, user);
          }

          this.currentUser = user;
          this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.CONFIG));
        },
        error: _ => {
          this.bearer = null;
        },
      }),
      switchMap(_ => {
        return this.loadOrganizations();
      }),
      tap({
        next: organizations => this._availableOrganizations$.next(organizations),
      }),
      map(() => null),
      castStream<void>(),
    );
  }

  logOut() {
    this.bearer = null;
    this.currentUser = null;

    if (!STATIC_ENVIRONMENT.production) {
      localStorage.removeItem(LocalStorageKey.NPROD_USER);
    }

    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.CONFIG, ConfigRoute.LOGIN));
  }

  loadOrganizations() {
    return of([
      {
        code: 'icz',
        name: 'ICZ a.s.',
        organizationState: OrganizationState.ACTIVE,
        id: 1,
        domain: undefined,
        note: undefined,
        createdAt: undefined,
        createdBy: undefined,
        activatedAt: undefined,
        activatedBy: undefined,
        editedAt: undefined,
        editedBy: undefined,
        deactivatedAt: undefined,
        deactivatedBy: undefined,
        deactivatedReason: undefined,
        deletedAt: undefined,
        deletedBy: undefined,
        deletedReason: undefined
      }
    ] as OrganizationDto[]).pipe(
      delay(500),
    );
  }

  reloadOrganizations() {
    this.loadOrganizations().subscribe(
      organizations => this._availableOrganizations$.next(organizations)
    );
  }

}
