import {inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ConfigCodebookService} from '|modules/config/services/config-codebook.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigCurrentOrganizationService {

  private configCodebookService = inject(ConfigCodebookService);

  availableOrganizationOptions$ = this.configCodebookService.organizationViewOptions();

  private _currentOrganizationId$ = new BehaviorSubject<Nullable<number>>(null);
  currentOrganizationId$ = this._currentOrganizationId$.asObservable();

  get currentOrganizationId() {
    return this._currentOrganizationId$.value;
  }

  refreshOrganizationsList() {
    this.availableOrganizationOptions$ = this.configCodebookService.organizationOptions();
  }

  changeCurrentOrganization(newOrganizationId: number) {
    this._currentOrganizationId$.next(newOrganizationId);
  }

}
