/**
 * Static environment characteristics defined in compile time.
 *
 * Dynamic environment characteristics such as BE URL or default language are
 * managed by EnvironmentService and loaded from /assets/environment/environment.json5.
 */
export interface StaticEnvironment {
  // Route to display after logging in / at startup while already logged in.
  defaultRoute: string;
  // Angular production flag
  production: boolean;
}

export const STATIC_ENVIRONMENT: StaticEnvironment = {
  defaultRoute: '/documents/dashboard',
  production: true,
};
