export enum ConfigRoute {
  BACK_TO_APPLICATION = '/',
  LOGIN = 'login',
  ORGANIZATIONS = 'organizations',
  ADMINISTRATORS = 'administrators',
  APPLICATIONS = 'applications-configuration',
  EXTERNAL_APPLICATIONS = 'external-applications',
  CONFIG_KEYS = 'configuration-keys',
  CONFIG_VALUES_STARTUP = 'config-values/startup',
  CONFIG_VALUES_RUNTIME = 'config-values/runtime',
  EFFECTIVE_CONFIGURATION = 'config-values/effective-configuration',
  DICTIONARY = 'dictionary-entries',
  TRANSACTION_EVENTS = 'transaction-events',
  PASSWORD_ENCRYPTION = 'password-encryption',
}
